/* globals Sentry, define */
import 'core-js/fn/object/entries'
import 'core-js/fn/object/values'
import '@webcomponents/custom-elements'
import 'intersection-observer'
import requirejs from 'requirejs'
import {getRjsConfig} from './rjs-config'
import * as sharedRegistry from './init/sharedRegistry'
import {initBeatEvents} from './bi/initBeatEvents'
import {BEATS} from './bi/constants'
import experimentFactory from 'santa-main-r/lib/lib/common/experimentFactory'
import getQueryUtils from 'santa-main-r/lib/lib/common/getQueryUtils.js'
import addExperimentsFromQuery from 'santa-main-r/lib/lib/common/addExperimentsFromQuery'
import overrideClientSpecMapScriptsFromQuery from 'santa-main-r/lib/lib/common/overrideClientSpecMapScriptsFromQuery'
import createLogger from './init/logger'
import createWorkerFactory from './init/platform/createWorker'
import iframesHandler from 'bolt-server/src/warmup/iframesHandler'
import {resourceHints, resourceHintsExtra} from './resource-hints'

window.santaBase = `${window.boltBase}/node_modules/wix-santa`
const {rendererModel, publicModel, location, navigator, performance = {}, isStreaming, serviceTopology, wixBiSession = {}, boltBase, santaBase, documentServicesModel} = window
window.wixBiSession = wixBiSession

const queryUtil = getQueryUtils(window)
window.queryUtil = queryUtil
const {getParameterByName, isParameterTrue} = queryUtil

if (isParameterTrue('forceResponsive')) {
    rendererModel.isResponsive = true
}

rendererModel.runningExperiments = addExperimentsFromQuery(rendererModel.runningExperiments, queryUtil, 'viewer')

const isPreview = typeof publicModel === 'undefined'
let cookie = '' // eslint-disable-line santa/no-module-state
try {
    cookie = document.cookie
} catch (ex) {
    console.log('Unable to get cookie from document. ', ex)
}
const requestModel = {
    userAgent: navigator.userAgent,
    cookie,
    deviceType: !isPreview && publicModel.deviceInfo ? publicModel.deviceInfo.deviceType : 'desktop'
}

const widgetsUrlOverride = getParameterByName('widgetsUrlOverride')
const viewerPlatformOverrides = getParameterByName('viewerPlatformOverrides')
const controllersUrlOverride = getParameterByName('controllersUrlOverride')
overrideClientSpecMapScriptsFromQuery(rendererModel.clientSpecMap, viewerPlatformOverrides, controllersUrlOverride, widgetsUrlOverride, serviceTopology.staticServerUrl)

const rawUrl = location.href
const isInSSR = false


// eslint-disable-next-line no-inner-declarations
function getLogger() {
    return {
        error: console.error,
        appLoadingPhaseStart: console.log,
        appLoaded: console.log,
        appLoadingPhaseFinish: console.log
    }
}

const logger = isPreview ? getLogger() : createLogger(Sentry, {rendererModel, publicModel, requestModel, rawUrl, boltBase, isInSSR, wixBiSession})
const experimentInst = experimentFactory.build(window)

const noop = () => {
}

window.performance = performance
window.performance.mark = performance.mark || noop
window.performance.now = performance.now || (() => Date.now())

const isLocal = getParameterByName('BoltSource').search(/^https?:\/\/localhost($|:|\/)/) === 0
const carmiDebug = getParameterByName('debug') === 'all' ||
        isParameterTrue('ssrDebug') ||
        isParameterTrue('carmiDebug') ||
        isLocal
const santaPackagesToDebug = (getParameterByName('debug') || '').split(',').filter(Boolean)

const ds = isParameterTrue('ds')
const rjsConfig = getRjsConfig(boltBase, santaBase, serviceTopology, {debug: carmiDebug, santaPackagesToDebug, ds, local: isLocal})

requirejs.config(rjsConfig)

define('mobx', [], () => ({
    runInAction: noop,
    action: noop,
    isObservableArray: noop
}))
define('mobx-react', [], () => ({
    observer: noop
}))

const CHUNK_NAMES = ['init', 'animations']

resourceHints(CHUNK_NAMES, window, boltBase, rendererModel, serviceTopology, requirejs, experimentInst)

const _import = async moduleName => new Promise((resolve, reject) => {
    requirejs([moduleName], module => {
        resolve(module)
    }, e => reject(e))
})

const loadAndCreateDocumentServices = async boltInstance => {
    const loadAllModules = Promise.all([
        import('bolt-ds-adapter'),
        _import('document-services-implementation'),
        _import('documentServicesSchemas')
    ])
    const [{createAdapter, createDocumentServices}, implementation, schemas] = await loadAllModules
    return createDocumentServices(createAdapter(boltInstance, implementation, schemas))
}


requirejs(['lodash'], _ => {
    const initPromise = import(/*webpackChunkName: "init"*/ './init')
    const boltAnimationsPromise = import(/*webpackChunkName: "animations"*/ 'bolt-animations/src/warmupAnimations')
    const isBot = _.get(rendererModel, 'seo')

    sharedRegistry.init()

    const reportBeatEvent = initBeatEvents(experimentInst, isBot)
    const {beatNumber, eventName} = BEATS.MAIN_R_LOADED
    reportBeatEvent(beatNumber, eventName)

    resourceHintsExtra(window, requirejs, experimentInst)

    initPromise.then(({init, createFunctionLibrary}) => {
        const isDebug = Boolean(getParameterByName('debug'))

        const fetchFunction = (url, options, dataType) => fetch(url, options || undefined)
            .then(res => {
                if (!res.ok && !_.get(options, 'allowErrors')) {
                    throw res
                }

                return res[dataType || 'json']()
            })

        const ssrModel = {
            isStreaming,
            isInSSR
        }

        let inHostBatch = false
        let boltInstanceFlush = null

        const hostInstanceBatchingStrategy = function () { // eslint-disable-line func-style
            setImmediate(() => {
                inHostBatch = true
                this.$endBatch()
                inHostBatch = false
                if (boltInstanceFlush) {
                    const savedInstanceFlush = boltInstanceFlush
                    boltInstanceFlush = null
                    savedInstanceFlush()
                }
            })
        }

        const boltInstanceBatchingStrategy = function () { // eslint-disable-line func-style
            if (inHostBatch) {
                boltInstanceFlush = this.$endBatch
            } else {
                this.$endBatch()
            }
        }
        const functionLibrary = createFunctionLibrary({
            fetchFunction,
            requireFunction: requirejs,
            workerFunction: createWorkerFactory(isDebug),
            biReporter: null,
            boltAnimationsPromise,
            logger
        })
        return init({
            logger,
            ssrModel,
            hostInstanceBatchingStrategy,
            boltInstanceBatchingStrategy,
            functionLibrary,
            rendererModel,
            rawSeoTags: undefined,
            documentServicesModel,
            publicModel,
            isPreview,
            serviceTopology,
            requestModel,
            rawUrl,
            wixBiSession,
            reportBeatEvent,
            registerToIframesLoadEvent: iframesHandler.registerForEvents,
            renderFlags: window.renderFlags || {},
            isBot,
            isDebug,
            santaBase,
            boltBase
        })
        // eslint-disable-next-line consistent-return
    }).then(({doneStagePromise}) => doneStagePromise).then(async result => {
        const isSsrSuccessfulAndSeo = result.hydrate && window.rendererModel.seo
        if (isSsrSuccessfulAndSeo) {
            return Promise.reject()
        }
        const {hostInstance, boltInstance, boltMain, hydrate, serverMarkup, indicator} = result
        const rootElement = document.getElementById('SITE_CONTAINER')
        await boltMain.renderClientSide(boltInstance, rootElement, hydrate, serverMarkup, indicator, logger)
        hostInstance.setRenderPhase(hydrate ? 'hydrate' : 'render')
        return boltInstance
    }).then(async boltInstance => { //eslint-disable-line consistent-return
        iframesHandler.flushEvents()
        if (ds) {
            return await loadAndCreateDocumentServices(boltInstance)
        }
    }).catch(e => {
        console.log(e)
    })
})
